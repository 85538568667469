
.MuiFormLabel-root.Mui-disabled {
    color: black !important;
}
.MuiCardHeader-root{
    /* background: #2E2E38; */
    background: #EDF1F5;
    height: 60px;
}

/* Button  */
.MuiCardHeader-action{
    margin: 0px !important;
    position: relative;
    top: 50%;
    transform: translatey(-50%);
}
.projects-stage-column{
    display: flex;
    flex: 1;
    justify-content: space-around;
}
.MuiTabs-flexContainer{
    justify-content: space-around;
}
.MuiTabPanel-root{
    padding:0px !important;
}

@media screen and (max-width: 1400px) {
    .projects-stage-column{
        display: block;
        text-align: center;
    }
}